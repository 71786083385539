@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap');

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-green: #00FF00;
  --primary-transparent-black: rgba(0, 0, 0, 0.7)
}

body {
  font-family: "Lato", sans-serif !important;
}


/**********NAVBAR************/
nav {
  z-index: 2;
}

.logo {
  width: 3.25rem;
}

/**********MENU LINKS************/
nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-bottom: 0.1875rem solid var(--primary-dark);
}

/* this is for all mouseover highlights */
.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-bottom: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-bottom: 0.1875rem solid var(--primary-orange);
}

/* for the logo */
.navbar-brand {
  color: var(--primary-white) !important;
  position: absolute;
  letter-spacing: 0.0375rem;
}
@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}


/**********HEADER************/
.header-wrapper {
  position: relative;
  background: url(blank-computer.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  z-index: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 30%;
  bottom: 15%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.main-info h1 {
  color: var(--primary-black);
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.1em 0.25em;
  z-index: 2;
}

.typed-text {
  font-size: 1.8rem;
  color: var(--primary-white);
  background-color: rgba(50, 50, 50, 0.8);
  padding: 0 0.25em;
}
@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}


/**********HEADER MAIN OFFER BUTTON************/
.main-info .btn-main-offer {
  color: var(--primary-red);
}

.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  text-decoration: none;
  font-weight: bold;
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}


/**********PARTICLES JS************/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}
@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}


/**********ABOUT ME************/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 26rem;
  border-radius: 15px;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.075em;
}

@media(max-width: 468px) {
  .profile-img {
    width: 15rem;
  }
}


/**********SERVICES************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.075em;
}

.services .circle {
  position: relative;
  margin: 0.5rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.services .icon {
  font-size: 1.5rem;
  color: var(--primary-light-grey);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: fit-content;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.5s ease-in-out;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-light-grey);
}

.services .box p {
  line-height: 1.35em;
}

@media(max-width: 768px) {
  
  .services .box p {
    line-height: 1.5em;
  }
}


/**********EXPERIENCE************/
.experience {
  padding: 0.5rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
  font-weight: bold;
  letter-spacing: 0.075em;
}

/* grey middle line */
.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block p {
  text-align: justify;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
  text-align: right;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 00.3125rem;
  color: var(--primary-red);
  font-weight: 500;
}

/**********MEDIA************/
@media(max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .timeline-block-right {
    float: none;
    width: 100%;
  }
  
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
    text-align: left;
  }
}


/**********PORTFOLIO************/
.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-black);
  font-weight: bold;
  letter-spacing: 0.075em;
}

.portfolio-image {
  width: 15rem;
  height: 8rem;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/**********OVERFLOW BOX************/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 7rem;
}

/**********OVERFLOW BOX************/
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
  margin: 0 auto;
}

.portfolio-image-container {
  display: flex;
  flex-direction: column;
}

.portfolio-image-container p {
  padding-top: 1em;
}

.hyper-link {
  word-wrap: break-word;
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  cursor: pointer;
  color: var(--primary-red);
}

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
  .popupbox-content p {
    line-height: 1.3em;
  }
}

/* when a mobile device is rotated, the pop-up box cuts off too much, so this is required */
@media(max-height: 570px) {
  .portfolio-image-popupbox {
    width: 50%;
  }
  .popupbox-wrapper {
    height: 100%;
  }
}


/**********CONTACTS************/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.075em;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.25rem;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin-top: 1rem;
  width: 20%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.25s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 700;
  color: var(--primary-green);
}

@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.5rem;
  }

  .contact-btn {
    width: 80%;
  }
}


/**********FOOTER************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

.footer-links {
  margin-bottom: 1.125em;
}